<template>
  <ValidationObserver v-slot="{ passes }">
    <v-form @submit.prevent="passes(create)">
      <v-card>
        <v-card-title>
          Create user
        </v-card-title>
        <v-card-text>
          <ValidationProvider
            v-slot="{ errors }"
            name="name"
            rules="required"
          >
            <v-text-field
              v-model="user.name"
              :error-messages="errors"
              label="Name"
              type="text"
              required
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="email"
            rules="required|email"
          >
            <v-text-field
              v-model="user.email"
              :error-messages="errors"
              label="Email"
              type="email"
              required
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="password"
            rules="required"
          >
            <v-text-field
              v-model="user.password"
              :error-messages="errors"
              label="Password"
              type="password"
              required
            />
          </ValidationProvider>
          <v-autocomplete
            v-model="user.role_id"
            :items="roles"
            item-value="id"
            item-text="display_name"
            label="Roles"
          />
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-spacer />
          <v-btn
            type="submit"
            color="primary"
            :loading="loading"
          >
            {{ $t('action.create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import authHeader from '@/utils'

export default {
  name: 'UserCreate',
  data: () => ({
    selected: [],
    loading: false,
    user: {},
    roles: []
  }),
  mounted () {
    this.getRoles()
  },
  methods: {
    getRoles () {
      this.$http
        .get('/roles', { headers: authHeader() })
        .catch(error => this.displayError(error))
        .then(({ data: { data } }) => {
          this.roles = data
        })
    },
    create () {
      this.loading = true
      this.$http
        .post('/users', this.user, { headers: authHeader() })
        .catch(error => {
          this.loading = false
          this.displayError(error)
        })
        .then(() => {
          this.$store.commit('setNotification', { color: 'success', message: this.$t('action.create_success') })
          this.$router.push({ name: 'dashboard.user' })
        })
    }
  }
}
</script>
